import Typography from "@material-ui/core/Typography";
import React, { useEffect, useState } from "react";
import RightArrowWhite from "../assets/Icons/accordian_white.svg";
import RightArrow from "../assets/Icons/go.svg";
import Clm2Img from "../assets/Icons/mail.svg";
import Clm1Img from "../assets/Icons/phone.svg";
import BackArrowIcon from "../assets/Images/accordian.svg";
import BannerBgImage from "../assets/Images/Insights/insight.png";
import MiniImageBannerBgImg from "../assets/Images/Insights/what-we-do-banner-image.png";
import ogimage from "../assets/Images/ogImages/OG_Insights.png";
import Button from "../components/Button";
import Layout from "../components/layouts/layout";
import DataService from "../services/api/data_services";
import { BASE_SITE_URL } from "../shared/constants";
//Image Banner
import ImageBanner from "../templates/ImageBanner";
//Info View
import Infoview from "../templates/InfoView";
//Mini Image Banner 1
import MiniImageBanner1 from "../templates/MiniImageBanner1";
import MixedView from "../templates/MixedView";
//Contact info
import TwoColumnContactInfo from "../templates/TwoColumnContactInfo";

const Insights = () => {
  const [articleRawData, setArticleRawData] = useState([]);
  const [articleLimit, setArticleLimit] = useState(3);
  const [articlePage, setArticlePage] = useState(1);
  const [page, setPage] = useState(1);
  const [articleData, setArticleData] = useState([]);
  const [isHover, setIsHover] = useState(false);
  const toggleHover = () => {
    setIsHover(!isHover);
  };

  useEffect(() => {
    DataService.getData(
      `/admin/api/insight?limit=${articleLimit}&page=${articlePage}&type=Article&component=mini_view&mixed_component=mixed_view`
    ).then((response) => {
      setArticleData([...articleData, ...response.data]);
      setArticleRawData(response);
      if (articlePage === 1) {
        setArticlePage(2);
      }
    });
  }, [articlePage, articleLimit]);

  /**
   * Image Banner // HIDING TEMPORATILY
   */
  const ImageBannerData = {
    bnrImg: BannerBgImage,
    bnrImgTitle: "Insights",
    subtitleclassname: "",
    bnrImgSubTitle: "All Resources",
    bnrImgClassName: "insightsbannerimg",
    servicesColumn1: {
      data: [
        {
          service: "Articles",
          link: "/insights/articles",
        },
      ],
    },
    servicesColumn2: {
      data: [
        {
          service: "White Papers",
          link: "/insights/white-papers",
        },
      ],
    },
    // servicesColumn3: {
    //   data: [
    //     {
    //       service: "Podcast",
    //       link: "/insights/podcast",
    //     },
    //   ],
    // },
  };

  /**
   * Infoview
   */
  const InfoviewData = {
    InfoviewTitle: (
      <div className="ttc">
        {" "}
        Learning is a lifelong adventure,
        <br />
        <span className="red-line3letter">wha</span>t are you in the mood for?
      </div>
    ),
    redline: false,
    InfoviewContent: (
      <>
        As we are deep rooted in the culture of learning and sharing, we are
        passionate about sharing our knowledge and expertise across technology.
        Uncover proprietary digital approaches provided by our expert team of
        digital leaders who want to achieve the extraordinary to speed up your
        path to business growth. Our articles and research papers will cover
        deep business topics to current technology trends. Deep dive and enjoy!
      </>
    ),
  };

  /**
   * Mini Image Banner 1
   */

  const MiniImageBanner1Data = {
    title: (
      <div className="whatwedo">
        What we do<div className="black-line"></div>
      </div>
    ),
    titleClass: "mini-image-banner-title-area title-header",
    image: MiniImageBannerBgImg,
    altTxt: "Who we work with",
    btnClassName: "what-we-do-resize-btn",
    btnLabel: "TAKE A LOOK",
    btnVariant: "contained",
    btnColor: "primary",
    route: "/what-we-do",
    underline: false,
    tooltip: "yes",
    tip: "TAKE A LOOK",
    underlineClass: "black-line",
  };

  /**
   * TwoColumnContactInfo
   */

  const TwoColumnContactInfoData = {
    Clm1Img: Clm1Img,
    Clm1Title: "Phone:",
    Clm1SubTitle: "+44 (0) 161 706 7983",
    Clm2Img: Clm2Img,
    Clm2Title: "Email:",
    Clm2SubTitle: "hello@digiryte.com",
  };
  return (
    <Layout
      seo={{
        title: "Insights | Digiryte",
        ogtitle: "Insights | Digiryte",
        description:
          "Explore Digiryte's insights to uncover proprietary digital approaches and gain valuable knowledge and expertise shared by our expert team in technology.",
        ogimage: ogimage,
        ogurl: `${BASE_SITE_URL}/insights`,
        keywords:
          "Digiryte insights, digital approaches, technology expertise, knowledge sharing",
      }}
    >
      <ImageBanner imageBannerData={ImageBannerData} />
      <div className="mt-50 body-container">
        <Button
          icon={
            <img
              className="effect-btn-svgicon"
              src={BackArrowIcon}
              alt="click"
            />
          }
          customClassName="effect-btn"
          className="mui-button-font"
          tip="Home"
          routepath="/"
        />
        <Typography
          style={{
            fontSize: "24px",
            fontWeight: "600",
            marginTop: "-42px",
            marginLeft: "40px",
          }}
        >
          Home
        </Typography>
      </div>
      <div className="mt-30">&nbsp;</div>
      <Infoview InfoviewData={InfoviewData} />
      {articleData && articleData.length > 0 && (
        <div className="mt-70">
          <MixedView miniviewData={articleData} />
        </div>
      )}
      {articleRawData.has_more && (
        <div style={{ width: "100%", textAlign: "center" }}>
          <div
            onMouseEnter={toggleHover}
            onMouseLeave={toggleHover}
            className="mt-60"
            role="presentation"
            onClick={() => {
              setArticlePage(
                articlePage === 1 ? articlePage + 2 : articlePage + 1
              );
              setArticleLimit(3);
            }}
            style={{ display: "inline-block" }}
          >
            <Button
              icon={
                <img
                  className="effect-btn-svgicon"
                  src={!isHover ? RightArrow : RightArrowWhite}
                  alt="MORE ARTICLES"
                />
              }
              customClassName="info-view-resize-btn"
              color="primary"
              variant="outlined"
              label="READ MORE"
              tip="READ MORE"
            />
          </div>
        </div>
      )}
      <div className="mt-50">
        <MiniImageBanner1
          id="one"
          MiniImageBanner1Data={MiniImageBanner1Data}
        />
      </div>
      <TwoColumnContactInfo
        TwoColumnContactInfoData={TwoColumnContactInfoData}
      />
    </Layout>
  );
};

export default Insights;
