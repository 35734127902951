import { Grid } from "@material-ui/core";
import React from "react";
import Minicard from "../../components/MiniCard";
import MegaView from "../../templates/MegaView";
import "../MiniView/MiniView.scss";

const MixedView = (props) => {
  return (
    <div className="body-container">
      <Grid container direction="row" spacing={3}>
        {props.miniviewData &&
          props.miniviewData.map((item, index) => (
            <Grid
              key={index}
              item
              xs={12}
              sm={item.type === "White Paper" ? 12 : 6}
              md={item.type === "White Paper" ? 12 : 4}
              className="card crd-spc"
            >
              {item.type === "White Paper" && (
                <MegaView MegaviewData={[item]} key={index} />
              )}
              {item.type === "Article" && (
                <Minicard
                  MiniviewHeading={item.meta_title}
                  MiniviewContent={
                    item.meta_description !== null ? item.meta_description : ""
                  }
                  MiniviewImage={item.og_image}
                  WhitePaperFooterLabel={item.type}
                  Slug={item.slug}
                  Type={item.slug_type}
                  SlugType={item.slug_type}
                  GatedContent={item.gated_content}
                  navigateComponent={props.navigateComponent}
                />
              )}
            </Grid>
          ))}
      </Grid>
    </div>
  );
};

export default MixedView;
